<template>
	<div class="user-list-container">
		<DsnPanel>
			<div slot="header" class="title clearfix">
				<el-form :inline="true" :model="queryParam" ref="userQueryForm" :rules="{}" @submit.native.prevent>
					<!-- <el-form-item :label="`${this.$t('users.fields.username')}:`" prop="username" size="medium">
						<dsn-input
							v-model="queryParam.username"
							size="medium"
							:placeholder="`${this.$t('commmsg.pleaseInput') + ' ' + this.$t('users.fields.username')}`"
						></dsn-input>
					</el-form-item> -->
					<!-- <el-form-item :label="`${this.$t('users.fields.phone')}:`" prop="phone" size="medium">
						<dsn-input v-model="queryParam.phone" size="medium" :placeholder="`${this.$t('commmsg.pleaseInput') + ' ' + this.$t('users.fields.phone')}`"></dsn-input>
					</el-form-item> -->
					<el-form-item :label="`${this.$t('users.fields.workNo')}/`+`${this.$t('users.fields.realname')}/`+`${this.$t('users.fields.phone')}:`" prop="realname" size="medium">
						<dsn-input
							v-model="queryParam.realname"
							size="medium"
							:placeholder="`${this.$t('commmsg.pleaseInput')+' '+this.$t('users.fields.workNo')+'/'+this.$t('users.fields.realname')+this.$t('users.fields.phone')}`"
						></dsn-input>
					</el-form-item>
					
					
					
					
					
					
					
					<el-form-item :label="`${this.$t('users.fields.role')}:`" prop="roleName" size="medium">
						<dsn-select
							 style="width: 250px;"
							v-model.trim="queryParam.roleId"
							filterable
							:placeholder="this.$t('commmsg.pleaseSelect')+' '+this.$t('users.fields.role')"
							size="medium"
						>
							<el-option v-for="item in RoleList" :key="item.id" :label="item.roleName" :value="item.id"></el-option>
						</dsn-select>
					</el-form-item>
					
					
					
					
					
					
					
					
					
					
					
					
					
					<el-form-item>
						<dsn-button size="medium" type="primary" icon="el-icon-search" @click="handleQuery(['userQueryForm'])">{{ $t('commbtn.query') }}</dsn-button>
						<dsn-button size="medium" type plain icon="el-icon-refresh" class="reset-button" @click="handleReset(['userQueryForm'])">
							{{ $t('commbtn.reset') }}
						</dsn-button>
					</el-form-item>
				</el-form>
			</div>
			<div class="operation">
				<dsn-button
					size="medium"
					type="primary"
					icon="el-icon-edit"
					:disabled="selectionList.length !== 1"
					@click="handleEdit"
					v-if="isEditBtn == true"
					style="margin-left:0"
				>
					{{ this.$t('commbtn.edit') }}
				</dsn-button>
			</div>

			<dsn-table
				@row-click="selectCheckBox"
				ref="multipleTable"
				:data="pagingConfig.tableData"
				v-loading="pagingConfig.tableLoading"
				tooltip-effect="dark"
				style="width: 100%;"
				@selection-change="handleSelectionChange"
			>
				<el-table-column type="selection" width="55" align="center" />
				<!--        <el-table-column type="index" label="序号"/>-->
				<!-- <el-table-column prop="username" :label="this.$t('users.fields.username')" align="center" width="150" /> -->
				<el-table-column prop="realname" :label="this.$t('users.fields.realname')" align="center" width="150" />
				<!-- <el-table-column prop="sex" :label="this.$t('users.fields.sex')" align="center" width="150"> -->
					<!-- <template scope="scope">
						{{ sexFormatter(scope.row.sex) }}
					</template>
				</el-table-column> -->
				<el-table-column :label="this.$t('users.fields.role')" align="center" width="200">
					<template scope="scope">
						{{ roleFormatter(scope.row) }}
					</template>
				</el-table-column>
				<el-table-column prop="workNo" :label="this.$t('users.fields.workNo')" align="right" width="150" />
				<el-table-column prop="email" :label="this.$t('users.fields.email')" align="center" width="250" />
				<el-table-column prop="phone" :label="this.$t('users.fields.phone')" align="center" width="150" />
				<el-table-column prop="post" :label="this.$t('users.fields.post')" align="center" width="150" />
				<!-- <el-table-column label="备注" align="center" width="200">
					<template scope="scope">
						{{ roleFormatter2(scope.row) }}
					</template>
				</el-table-column> -->
			</dsn-table>
			<DsnFooter>
				<dsn-pagination
					v-if="showPage"
					background
					layout="->,total,prev,pager,next,sizes"
					:total="pagingConfig.total"
					:page-size="pagingConfig.pageSize"
					:page-sizes="[5, 10, 15, 20]"
					:current-page="pagingConfig.current"
					@size-change="handlePageSizeChange"
					@current-change="handleCurrentChange"
				></dsn-pagination>
			</DsnFooter>
		</DsnPanel>
		<el-dialog title="删除" :visible.sync="deleteDialog" :width="defaltDialogWidth" v-el-drag-dialog>
			<span>是否确认删除{{ selectionList.length }}条数据？</span>
			<span slot="footer" class="dialog-footer">
				<dsn-button @click="deleteDialog = false">取 消</dsn-button>
				<dsn-button type="primary" @click="handleDelete">确 定</dsn-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
import { mapMutations } from 'vuex';
import DsnFooter from '@/views/layout/dsn-footer';
import { deleteUserById, getUserList } from '@/api/efms/users/users.api';
import {getRoleList} from "@/api/efms/roles/role.api";

export default {
	name: 'equipmentPositionRefSettings',
	components: { DsnFooter },
	inject: ['defaltDialogWidth'],
	data() {
		return {
			listData: [],
			queryParam: {
				username: null,
				realname: null,
				roleId:null,
			},
			showPage: true,
			pagingConfig: {
				current: 1,
				pageSize: 10,
				count: 0,
				tableLoading: false,
				total: 0,
				tableData: []
			},
			deleteDialog: false,
			selectionList: [],

			isEditBtn: false,
			
			RoleList:[],
		};
	},
	computed: {},
	created() {
		this.getSysUserList();
		this.getRoleList();

		var permissionsList = JSON.parse(localStorage.getItem('USER_PERMISSIONS'));

		if (permissionsList.indexOf('api:users:edit') != -1) {
			this.isEditBtn = true;
		}
	},
	activated() {
		this.getSysUserList();
		this.getRoleList();
	},
	methods: {
		getRoleList(){
			const data = {
			  page: 1,
			  pageSize: 999999,
			};
			getRoleList(data).then((response) => {
			  const {data, status} = response;
			  if (status === 200) {
			    this.RoleList = [...data.data]
			  } else {
			    this.notification().warn({
			      body: data.errMsg
			    });
			  }
			})
		},
		...mapMutations(['USERSLIST']),
		sexFormatter(data) {
			return data === 1 ? this.$t('users.fields.male') : this.$t('users.fields.female');
		},
		roleFormatter(data) {
			let roles = data.roles;
			let roleNames = '';
			if (roles != null && roles.length > 0) {
				let tempList = [];
				roles.forEach(v => {
					tempList.push(v.roleName);
				});
				roleNames = tempList.join(',');
			}
			return roleNames;
		},
		roleFormatter2(data) {
			let roles = data.roles;
			let remarks = '';
			if (roles != null && roles.length > 0) {
				let tempList = [];
				roles.forEach(v => {
					tempList.push(v.remark);
				});
				remarks = tempList.join(',');
			}
			return remarks;
		},
		handleQuery(formNames) {
			this.$refs[formNames].validate(valid => {
				if (valid) {
					this.pagingConfig.current = 1;
					this.getSysUserList();
				} else {
					return false;
				}
			});
		},
		handleReset(formName) {
			console.info(this.$refs[formName]);
			this.$refs[formName].resetFields();
			this.handleQuery(formName);
		},
		handlePageSizeChange(pageSize) {
			this.pagingConfig.pageSize = pageSize;
			this.pagingConfig.current = 1;
			this.getSysUserList();
		},
		handleCurrentChange(currentChange) {
			this.pagingConfig.current = currentChange;
			this.getSysUserList();
		},
		handleSelectionChange(val) {
			this.selectionList = val;
		},
		selectCheckBox(row) {
			this.$refs.multipleTable.toggleRowSelection(row);
		},
		handleEdit() {
			const tempArr = JSON.parse(JSON.stringify(this.selectionList));
			this.USERSLIST(tempArr);
			localStorage.setItem('userList', JSON.stringify(this.selectionList));
			this.$router.push({
				name: 'userEdit',
				query: { operateType: 'edit' }
			});
		},
		handleAdd() {
			// const tempArr = JSON.parse(JSON.stringify(this.selectionList));
			this.USERSLIST([]);
			// localStorage.setItem("positionRefList", JSON.stringify(this.selectionList));
			this.$router.push({
				name: 'userAdd',
				query: { operateType: 'add' }
			});
		},
		handleDelete() {
			const { selectionList } = this;
			const params = selectionList.map(item => ({
				id: item.id
			}));

			deleteUserById(params).then(result => {});
		},
		handleDeleteCheck() {
			this.messageBox()
				.then(() => {
					this.handleDelete();
				})
				.catch(() => {
					this.notification().warn({
						body: '已经取消删除'
					});
				});
		},
		getSysUserList() {
			this.initMainList();
		},
		initMainList(showPage) {
			if (!showPage) {
				this.pagingConfig.currentPage = 1;
			}
			const data = {
				...this.queryParam,
				page: this.pagingConfig.current,
				pageSize: this.pagingConfig.pageSize
			};
			this.showPage = showPage;
			this.pagingConfig.tableLoading = true;
			getUserList(data).then(response => {
				const { data, status } = response;
				this.pagingConfig.tableLoading = false;
				if (status === 200) {
					this.showPage = true;
					this.pagingConfig.total = data.totalCount;
					this.pagingConfig.tableData = [...data.data];
					// this.notification().success({
					//   body: '请求成功',
					//   duration: 1
					// });
				} else {
					this.notification().warn({
						body: data.errMsg
					});
				}
			});
		}
	}
};
</script>
<style lang="scss" scoped>
.user-list-container {
	.operation {
		width: 100%;
		height: 48px;

		.dsn-button {
			margin: 0 8px 0 0;
		}
	}
}

//::v-deep.el-table .cell {
//  font-size: 18px;
//}

//::v-deep .el-form-item__label {
//  font-size: 25px;
//}
//
//::v-deep .el-form-item__content {
//  font-size: 25px;
//}
</style>

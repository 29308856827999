<template>
  <div class="user-add-container">
    <div class="operate">
      <dsn-button
          size="small"
          icon="el-icon-back"
          type="primary"
          @click.native="handleBack"
      >{{ this.$t('commbtn.back') }}
      </dsn-button>
      <!--      <dsn-button-save-->
      <!--          @click.native="checkAdd('materialGroupForm')"-->
      <!--          v-if="hasUpdateBtnAuth"-->
      <!--      >保存-->
      <dsn-button-save
          @click.native="checkAdd(['userFormRef'])">{{ this.$t('commbtn.save') }}
      </dsn-button-save
      >
      <dsn-button
          size="medium"
          type
          plain
          icon="el-icon-refresh"
          class="reset-button"
          @click.native="handleReset(['userFormRef'])"
      >{{ this.$t('commbtn.reset') }}
      </dsn-button>
    </div>
    <div class="info">
      <el-form
          :model="userForm"
          ref="userFormRef"
          :inline="true"
          :rules="userFormRules"
          class="userFormTop"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item :label="this.$t('users.fields.username')" prop="username" :label-width="labelWidth">
              <dsn-input
                  v-model.trim="userForm.username"
                  placeholder="请输入登录账号"
                  :disabled="isEditStation"
              ></dsn-input>
            </el-form-item>
            <el-form-item :label="this.$t('users.fields.realname')" prop="realname" :label-width="labelWidth">
              <dsn-input
                  v-model.trim="userForm.realname"
                  placeholder="请输入用户姓名"
              ></dsn-input>
            </el-form-item>

          </el-col>
          <el-col>
            <el-form-item :label="this.$t('users.fields.pwd')" prop="password" :label-width="labelWidth">
              <dsn-input
                  type="password"
                  v-model.trim="userForm.password"
                  placeholder="请输入账号密码"
              ></dsn-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item :label="this.$t('users.fields.email')" prop="email" :label-width="labelWidth">
              <dsn-input
                  v-model.trim="userForm.email"
                  placeholder="请输入邮箱地址"
              ></dsn-input>
            </el-form-item>
            <el-form-item :label="this.$t('users.fields.phone')" prop="phone" :label-width="labelWidth">
              <dsn-input
                  v-model.trim="userForm.phone"
                  placeholder="请输入联系电话"
              ></dsn-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item :label="this.$t('users.fields.sex')" prop="sex" :label-width="labelWidth">
              <el-select v-model="userForm.sex" placeholder="请选择性别">
                <el-option
                    v-for="item in sexOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item :label="this.$t('users.fields.department')" prop="departmentId" :label-width="labelWidth">
              <dsn-input
                  v-model.trim="userForm.departmentId"
                  placeholder="请选择所在部门"
              ></dsn-input>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
    </div>
  </div>

</template>
<script>
import {addUser} from "@/api/efms/users/users.api";

export default {
  name: 'userAdd',
  data() {
    return {
      isEditStation: false, //是否可编辑站位
      userForm: {
        username: 'charlie',
        realname: 'charlie',
        password: '123456',
        sex: '1',
        email: "335903195@qq.com",
        phone: "123456",
        departmentId: null,
      },
      labelWidth: "100px",
      userFormRules: {
        username: [{required: true, message: "请输入账号", trigger: "change"}],
        realname: [{required: true, message: "请输入用户姓名", trigger: "change"}],
        password: [{required: true, message: "请输入密码", trigger: "change"}],
      },
      sexOptions: [
        {
          "label": '男',
          "key": "1",
          "value": "1",
        },
        {
          "label": '女',
          "key": "0",
          "value": "0",
        }
      ]
    }
  },
  created() {
    this.operateType = this.$route.query.operateType;
  },
  methods: {
    handleBack() {
      this.$router.pushAndClear(
          {name: "userList"},
          {
            deleteItem: this.$route,
            current: this.$route,
          }
      );
    },
    checkAdd(formName) {
      let count = 0;
      formName.forEach((element) => {
        this.$refs[element].validate((valid) => {
          if (valid) {
            count++;
          } else {
            return false;
          }
        });
      });
      if (count >= 1) {
        this.messageBox({
          type: "success",
          title: "保存",
          content: "是否保存数据?",
        })
            .then(() => {
              this.handleDataSave();
            })
            .catch(() => {
              this.notification().warn({
                body: "已取消保存",
              });
            });
      }
    },
    handleDataSave() {
      const postData = {};
      postData['username'] = this.userForm.username
      postData['password'] = this.userForm.password
      postData['realname'] = this.userForm.realname
      postData['sex'] = this.userForm.sex
      postData['email'] = this.userForm.email
      postData['phone'] = this.userForm.phone
      addUser(postData).then(result => {
        const {code, errMsg} = result.data;
        if (code === 200) {
          this.notification().success({
            body: "保存成功",
          });
          this.handleBack();
          return;
        } else {
          this.notification().error({
            body: errMsg,
          });
        }
      }).catch(err => {
        this.notification().error({
          body: this.$t('errors.fields.serverInternalError'),
        });
      })
    },
    handleReset(formName) {
      if (this.operateType === "add") {
        formName.forEach((element) => {
          this.$refs[element].resetFields();
        });
      }
    }
  }
}
</script>
<style lang="scss">

.user-add-container {
  .operate {
    padding: 16px;

    .el-button {
      margin: 0 8px 0 0;
    }
  }

  .info {
    background-color: #ffffff;
    padding: 16px;

    .userFormTop {
      .el-form-item {
        margin: 0 24px 16px 0;
      }
    }

    .userForm {
      .el-form-item {
        margin: 0 24px 16px 0;

        .el-select {
          width: 320px;
        }

        .el-input {
          width: 320px;
        }
      }
    }
  }
}
</style>

<template>
  <div class="user-edit-container">
    <div class="operate">
      <dsn-button
          size="small"
          icon="el-icon-back"
          type="primary"
          @click.native="handleBack"
      >返回
      </dsn-button>
      <!--      <dsn-button-save-->
      <!--          @click.native="checkAdd('materialGroupForm')"-->
      <!--          v-if="hasUpdateBtnAuth"-->
      <!--      >保存-->
      <dsn-button-save
          @click.native="checSave(['userFormRef'])">保存
      </dsn-button-save
      >
      <dsn-button
          size="small"
          type
          plain
          icon="el-icon-refresh"
          class="reset-button"
          @click.native="handleReset(['userFormRef'])"
      >重置
      </dsn-button>
    </div>
    <div class="info">
      <el-form
          :model="userForm"
          ref="userFormRef"
          :inline="true"
          :rules="userFormRules"
          class="userFormTop"
      >
        <el-row>
          <el-col :span="24">
            <!-- <el-form-item label="登录账号" prop="username" :label-width="labelWidth">
              <dsn-input
                  v-model.trim="userForm.username"
                  placeholder="请输入登录账号"
                  :disabled="!isEditStation"
              ></dsn-input>
            </el-form-item> -->
            <el-form-item label="用户姓名" prop="realname" :label-width="labelWidth">
              <dsn-input
                  v-model.trim="userForm.realname"
				  disabled="true"
                  placeholder="请输入用户姓名"
              ></dsn-input>
            </el-form-item>
          </el-col>
          <!--          <el-col>-->
          <!--            <el-form-item label="账号密码" prop="password" :label-width="labelWidth">-->
          <!--              <dsn-input-->
          <!--                  type="password"-->
          <!--                  v-model.trim="userForm.password"-->
          <!--                  placeholder="请输入账号密码"-->
          <!--              ></dsn-input>-->
          <!--            </el-form-item>-->
          <!--          </el-col>-->
		  <el-col>
		    <el-form-item label="联系电话" prop="phone" :label-width="labelWidth">
		      <dsn-input
		          v-model.trim="userForm.phone"
		          placeholder="请输入联系电话"
		      ></dsn-input>
		    </el-form-item>
		  </el-col>
          <el-col>
            <el-form-item label="邮箱地址" prop="email" :label-width="labelWidth">
              <dsn-input
                  v-model.trim="userForm.email"
                  placeholder="请输入邮箱地址"
              ></dsn-input>
            </el-form-item>
          </el-col>
          
          <!--          <el-col>-->
          <!--            <el-form-item label="所在部门" prop="departmentId" :label-width="labelWidth">-->
          <!--              <dsn-input-->
          <!--                  v-model.trim="userForm.departmentId"-->
          <!--                  placeholder="请选择所在部门"-->
          <!--              ></dsn-input>-->
          <!--            </el-form-item>-->
          <!--          </el-col>-->
          <el-col>
            <el-form-item label="SAP账号" prop="workNo" :label-width="labelWidth">
              <dsn-input
                  v-model.trim="userForm.workNo"
                  placeholder="请输入SAP账号"
              ></dsn-input>
            </el-form-item>
          </el-col>
		  <!-- <el-col>
		    <el-form-item label="性别" prop="sex" :label-width="labelWidth">
		      <el-select v-model="userForm.sex" placeholder="请选择性别"style="width:225px">
		        <el-option
		            v-for="item in sexOptions"
		            :key="item.value"
		            :label="item.label"
		            :value="item.value">
		        </el-option>
		      </el-select>
		    </el-form-item>
		  </el-col> -->
          <el-col>
            <el-form-item :label="this.$t('users.fields.role')" prop="roleIds" :label-width="labelWidth">
              <el-select
                  v-model="userForm.roleIds"
                  style="width:320px"
				  filterable
                  placeholder="请选择角色"
				  @change="roleidChange"
              >
                <el-option
                    v-for="item1 in roleList"
                    :key="item1.value"
                    :label="item1.label"
                    :value="item1.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
		  
		  <el-col>
		    <el-form-item label="职务" prop="post" :label-width="labelWidth">
		      <dsn-input
		          v-model.trim="userForm.post"
		          placeholder="请输入职务"
		      ></dsn-input>
		    </el-form-item>
		  </el-col>
        </el-row>

      </el-form>
    </div>
  </div>

</template>
<script>
import {assignUserRole, updateUser} from "@/api/efms/users/users.api";
import _ from "lodash";
import {mapGetters} from "vuex";
import {getRoleList} from "@/api/efms/roles/role.api";

export default {
  name: 'userAdd',
  data() {
    return {
      isEditStation: false, //是否可编辑站位
      userForm: {
        id: null,
        username: null,
        realname: null,
        password: null,
        workNo: null,
        sex: null,
        email: null,
        phone: null,
        departmentId: null,
        roleIds: null,
		post:null,
      },
      labelWidth: "100px",
      userFormRules: {
        username: [{required: true, message: "请输入账号", trigger: "change"}],
        realname: [{required: true, message: "请输入用户姓名", trigger: "change"}],
        password: [{required: true, message: "请输入密码", trigger: "change"}],
        workNo: [{required: false, message: "请输入SAP账号", trigger: "change"}],
      },
      stationStatusList: [], //站位状态
      stationTypeList: [], //站位类型
      sexOptions: [
        {
          "label": '男',
          "key": 1,
          "value": 1,
        },
        {
          "label": '女',
          "key": 0,
          "value": 0,
        }
      ],
      roleList: [],

    }
  },
  created() {
    this.initRoleList();
    this.operateType = this.$route.query.operateType;
    this.cloneList = _.cloneDeep(JSON.parse(JSON.stringify(this.userList)));
    this.userForm =
        this.cloneList[0] ||
        JSON.parse(localStorage.getItem("userList"))[0];
    if (this.userForm.roles != null) {
      let role = this.userForm.roles[0];
      this.userForm.roleIds = role.id
    }
  },
  activated() {
    this.initRoleList();
  },
  computed: {
    ...mapGetters(["userList"]),
  },
  methods: {
	  roleidChange(val){
		  this.userForm.roleIds = val;
		  this.$forceUpdate();
	  },
    handleBack() {
      this.$router.pushAndClear(
          {name: "userList"},
          {
            deleteItem: this.$route,
            current: this.$route,
          }
      );
    },
    checSave(formName) {
      let count = 0;
      formName.forEach((element) => {
        this.$refs[element].validate((valid) => {
          if (valid) {
            count++;
          } else {
            return false;
          }
        });
      });
      if (count >= 1) {
        this.messageBox({
          type: "success",
          title: "保存",
          content: "是否保存数据?",
        })
            .then(() => {
              this.handleDataSave();
            })
            .catch(() => {
              this.notification().warn({
                body: "已取消保存",
              });
            });
      }
    },
    handleDataSave() {
      const postData = {};
      postData['username'] = this.userForm.username
      // postData['password'] = this.userForm.password
      postData['realname'] = this.userForm.realname
      postData['sex'] = this.userForm.sex
      postData['email'] = this.userForm.email
      postData['phone'] = this.userForm.phone
	  postData['post'] = this.userForm.post
      postData['workNo'] = this.userForm.workNo
      postData['departmentId'] = this.userForm.departmentId
      postData['id'] = this.userForm.id
	  console.log(postData);
      updateUser(postData).then(result => {
        const {status, data} = result;
        if (status === 200 && data.resultCode === 0) {
          this.notification().success({
            body: "更新成功!",
          });
        } else {
          this.notification().error({
            body: data.errMsg,
          });
        }
      })
      let userList = [];
      userList.push(this.userForm.id);
      let userRolePostData = {
        roleId: this.userForm.roleIds == null || this.userForm.roleIds === '' ? null : this.userForm.roleIds,
        userIdList: userList,
      }
	  console.log(userRolePostData);
      assignUserRole(userRolePostData).then(res => {
        const {status, data} = res;
        if (status === 200 && data.resultCode === 0) {
          this.handleBack();
        } else {
          this.notification().error({
            body: data.errMsg,
          });
        }
      });
    },
    handleReset(formName) {
      if (this.operateType === "edit") {
        this.userForm = _.cloneDeep(this.cloneList[0]);
      }
    },
    initRoleList() {
      getRoleList({
        page: 0,
        pageSize: 0
      }).then(res => {
        const {status, data} = res;
        if (status === 200 && data.resultCode === 0) {
          let tempList = data.data
          let result = [];
          if (tempList != null && tempList.length > 0) {
            for (let i = 0; i < tempList.length; i++) {
              let role = tempList[i];
              result.push({
                id: role.id,
                label: role.roleName,
                value: role.id
              })
            }
          }
          this.roleList = [...result]
        } else {
          this.roleList = []
          this.notification().error({
            body: data.errMsg,
          });
        }
      })
    }
  }
}
</script>
<style lang="scss">

.user-edit-container {
  .operate {
    padding: 16px;

    .el-button {
      margin: 0 8px 0 0;
    }
  }

  .info {
    background-color: #ffffff;
    padding: 16px;

    .userFormTop {
      .el-form-item {
        margin: 0 24px 16px 0;
      }
    }

    .userForm {
      .el-form-item {
        margin: 0 24px 16px 0;

        .el-select {
          width: 320px;
        }

        .el-input {
          width: 320px;
        }
      }
    }
  }
}
</style>
